import { getSiblings } from "../../base/js/core";
const typeCheckbox = document.querySelectorAll('.b-filter__type-checkbox')
const typeCheckboxAll = document.querySelector('.b-filter__type-checkbox.js-all')
typeCheckbox.forEach($checkbox => {
	$checkbox.addEventListener('change', evt => {
		if (evt.target.classList.contains('js-all')) {
			if (evt.target.checked) {
				typeCheckbox.forEach($inp => {
					$inp.checked = true;
				})
			} else {
				typeCheckbox.forEach($inp => {
					$inp.checked = false;
				})
			}
		} else {
			typeCheckboxAll.checked = false;
		}
	})
});
import Swiper, { Navigation } from 'swiper'
const arrCarousel = document.querySelectorAll('.b-filter');
arrCarousel.forEach($section => {
	let $carousel = $section.querySelector('.swiper')
	let $prev = $section.querySelector('.swiper-button-prev')
	let $next = $section.querySelector('.swiper-button-next')
	let options = {
		modules: [Navigation],
		slidesPerView: 1,
		loop: true,
		navigation: {
			nextEl: $next,
			prevEl: $prev
		},
		breakpoints: {
			320: {
				slidesPerView: 1,
				spaceBetween: 0
			},
			768: {
				slidesPerView: 2,
				spaceBetween: 15
			},
			992: {
				slidesPerView: 3,
				spaceBetween: 6
			},
			1200: {
				slidesPerView: 4,
				spaceBetween: 6
			}
		}
	}
	let swiper = new Swiper($carousel, options)
})

