import Swiper, { Navigation } from 'swiper'
import { breakpoints } from '../../base/js/core'

const arrSectionsNav = document.querySelectorAll('.s-sections-nav')
arrSectionsNav.forEach($hall => {
	if ($hall.classList.contains('s-sections-nav_not-carousel')) {
		return;
	}
	let $carousel = $hall.querySelector('.swiper');
	let $prev = $hall.querySelector('.swiper-button-prev')
	let $next = $hall.querySelector('.swiper-button-next')
	let options = {
		modules: [Navigation],
		loop: true,
		navigation: {
			nextEl: $next,
			prevEl: $prev
		}
	}
	let swiper = new Swiper($carousel, options)
	const swiperResize = () => {
		const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
		if (width > breakpoints.tablet - 1) {
			swiper.destroy(false, true)
		} else {
			swiper = new Swiper($carousel, options)
		}
	}
	swiperResize()
	window.addEventListener('resize', () => swiperResize())
})
