import Swiper, { Navigation } from 'swiper'
const arrSectCarousel = document.querySelectorAll('.s-officially');
arrSectCarousel.forEach($section => {
	let $carousel = $section.querySelector('.swiper')
	let $prev = $section.querySelector('.swiper-button-prev')
	let $next = $section.querySelector('.swiper-button-next')
	let options = {
		modules: [Navigation],
		slidesPerView: 1,
		spaceBetween: 36,
		loop: true,
		navigation: {
			nextEl: $next,
			prevEl: $prev
		},
		breakpoints: {
			320: {
				slidesPerView: 1,
				spaceBetween: 0
			},
			768: {
				slidesPerView: 'auto',
				spaceBetween: 15
			},
			992: {
				slidesPerView: 'auto',
				spaceBetween: 30
			}
		}
	}
	let swiper = new Swiper($carousel, options)
})
