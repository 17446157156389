import Swiper, { Navigation } from 'swiper'
const arrSectCarousel = document.querySelectorAll('.s-partners');
arrSectCarousel.forEach($section => {
	let $carousel = $section.querySelector('.swiper');
	let $prev = $section.querySelector('.swiper-button-prev');
	let $next = $section.querySelector('.swiper-button-next');
	let arImages = $section.querySelectorAll('.swiper-slide img');
	let options = {
		modules: [Navigation],
		slidesPerView: 'auto',
		spaceBetween: 30,
		loop: true,
		navigation: {
			nextEl: $next,
			prevEl: $prev
		},
		breakpoints: {
			320: {
				slidesPerView: 'auto',
				spaceBetween: 30
			},
			768: {
				slidesPerView: 'auto',
				spaceBetween: 15
			},
			992: {
				slidesPerView: 'auto',
				spaceBetween: 47
			}
		}
	}
	arImages.forEach($img => {
		$img.onload = function () {
			this.closest('.swiper-slide').style.maxWidth = `${this.width}px`;
		}
	});
	let swiper = new Swiper($carousel, options);
})
