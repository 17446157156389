import { slideToggle } from '../../base/js/core';

const arrMenuSubmenuLnk = document.querySelectorAll('.b-menu .has-submenu');
arrMenuSubmenuLnk.forEach($lnk => {
	const $parent = $lnk.closest('li');
	const $dropdown = $parent.querySelector('.b-menu__submenu') || $parent.querySelector(' ul')
	$lnk.addEventListener('click', (e) => {
		const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
		if (width < 992) {
			e.preventDefault();
			slideToggle($dropdown)
			$lnk.classList.toggle('is-open');
		}
	});
});
const arrBtnsToggleMenu = document.querySelectorAll('.js-toggle-menu');
const $menu = document.getElementById('mobile-nav');
const $html = document.querySelector('html');
arrBtnsToggleMenu.forEach($btn => {
	$btn.addEventListener('click', (e) => {
		e.preventDefault();
		$html.classList.toggle('is-menu-open')
		$menu.classList.toggle('is-open');
	})
});
