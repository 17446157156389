import Swiper, { Navigation } from 'swiper'
const arrCarousel = document.querySelectorAll('.b-reviews');
arrCarousel.forEach($section => {
	let $carousel = $section.querySelector('.swiper')
	let $prev = $section.querySelector('.swiper-button-prev')
	let $next = $section.querySelector('.swiper-button-next')
	let options = {
		modules: [Navigation],
		slidesPerView: 1,
		spaceBetween: 0,
		loop: true,
		navigation: {
			nextEl: $next,
			prevEl: $prev
		}
	}
	let swiper = new Swiper($carousel, options)
})
