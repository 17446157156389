import Swiper, { Navigation } from 'swiper'
import {slideToggle} from "../../base/js/core";
const arrCarousel = document.querySelectorAll('.b-review');
arrCarousel.forEach($section => {
	let $carousel = $section.querySelector('.swiper')
	let $prev = $section.querySelector('.swiper-button-prev')
	let $next = $section.querySelector('.swiper-button-next')
	let options = {
		modules: [Navigation],
		slidesPerView: 1,
		spaceBetween: 0,
		loop: true,
		navigation: {
			nextEl: $next,
			prevEl: $prev
		},
		breakpoints: {
			320: {
				slidesPerView: 1,
				spaceBetween: 0
			},
			768: {
				slidesPerView: 3,
				spaceBetween: 15
			},
			992: {
				slidesPerView: 4,
				spaceBetween: 26
			},
			1200: {
				slidesPerView: 4,
				spaceBetween: 54
			}
		}
	}
	let swiper = new Swiper($carousel, options)
})
const arrMore = document.querySelectorAll('.b-review__more-lnk');
arrMore.forEach($lnk => {
	let $dropdown = $lnk.closest('.b-review').querySelector('.b-review__desc-hide');

	$lnk.addEventListener('click', e => {
		e.preventDefault();
		let txt = !$lnk.classList.contains('is-active') ? 'Скрыть' : 'Читать полностью';
		$lnk.classList.toggle('is-active');
		$lnk.textContent = txt;
		slideToggle($dropdown)
	})
})
