import './s-header/s-header';
import './b-menu/b-menu';
import './s-main-banner/s-main-banner';
import './s-halls/s-halls';
import './s-carousel/s-carousel';
import './s-officially/s-officially';
import './s-partners/s-partners';
import './s-sections-nav/s-sections-nav';
import './b-carousel/b-carousel';
import './b-reviews/b-reviews';
import './b-review/b-review';
import './b-add-review/b-add-review';
import './b-filter/b-filter';

