import { Fancybox } from '@fancyapps/ui'
Fancybox.bind('[data-fancybox]');
export const breakpoints = {
	phone: 0,
	tablet: 768,
	desktop: 992
}
const anchors = document.querySelectorAll('.js-anchore');
anchors.forEach($button => {
	$button.addEventListener('click', (e) => {
		e.preventDefault();
		const $el = document.getElementById($button.getAttribute('href').substring(1));
		if ($el) {
			const elPosition = $el.getBoundingClientRect().top - 20;
			// const offsetPosition = elementPosition - topOffset;
			window.scrollBy({
				top: elPosition,
				behavior: 'smooth'
			});
		}
	})
});
export let slideUp = (target, duration = 500, callback) => {
	target.style.transitionProperty = 'height, margin, padding'
	target.style.transitionDuration = `${duration}ms`
	target.style.boxSizing = 'border-box'
	target.style.height = `${target.offsetHeight}px`
	// eslint-disable-next-line no-unused-expressions
	target.offsetHeight
	target.style.overflow = 'hidden'
	target.style.height = 0
	target.style.paddingTop = 0
	target.style.paddingBottom = 0
	target.style.marginTop = 0
	target.style.marginBottom = 0
	window.setTimeout(() => {
		target.style.display = 'none'
		target.style.removeProperty('height')
		target.style.removeProperty('padding-top')
		target.style.removeProperty('padding-bottom')
		target.style.removeProperty('margin-top')
		target.style.removeProperty('margin-bottom')
		target.style.removeProperty('overflow')
		target.style.removeProperty('transition-duration')
		target.style.removeProperty('transition-property')
		if (callback) {
			callback()
		}
	}, duration)
}

export let slideDown = (target, duration = 500, callback) => {
	target.style.removeProperty('display')
	let display = window.getComputedStyle(target).display

	if (display === 'none') {
		display = 'block'
	}
	target.style.display = display
	let height = target.offsetHeight
	target.style.overflow = 'hidden'
	target.style.height = 0
	target.style.paddingTop = 0
	target.style.paddingBottom = 0
	target.style.marginTop = 0
	target.style.marginBottom = 0
	// eslint-disable-next-line no-unused-expressions
	target.offsetHeight
	target.style.boxSizing = 'border-box'
	target.style.transitionProperty = 'height, margin, padding'
	target.style.transitionDuration = `${duration}ms`
	target.style.height = `${height}px`
	target.style.removeProperty('padding-top')
	target.style.removeProperty('padding-bottom')
	target.style.removeProperty('margin-top')
	target.style.removeProperty('margin-bottom')
	window.setTimeout(() => {
		target.style.removeProperty('height')
		target.style.removeProperty('overflow')
		target.style.removeProperty('transition-duration')
		target.style.removeProperty('transition-property')
		if (callback) {
			callback()
		}
	}, duration)
}

export let slideToggle = (target, duration = 500, callback) => {
	let display = window.getComputedStyle(target).display;
	if (display === 'none') {
		slideDown(target, duration, callback)
	} else {
		slideUp(target, duration, callback)
	}
}
const getChildren = (n, skipMe) => {
	let r = []
	for (; n; n = n.nextSibling) {
		if (n.nodeType === 1 && n !== skipMe) {
			r.push(n)
		}
	}

	return r
}
export const getSiblings = (n) => {
	return getChildren(n.parentNode.firstChild, n)
}
export const prevAll = (element) => {
	const result = [];
	while (element = element.previousElementSibling) {
		result.push(element);
	}

	return result;
}

export const validate = (selector) => {
	const inputsValidate = document.querySelectorAll(selector)
	inputsValidate.forEach($input => {
		$input.addEventListener('invalid', (e) => {
			e.preventDefault()
			$input.classList.add('is-invalid')
		})
		$input.addEventListener('input', () => {
			$input.classList.remove('is-invalid')
			if ($input.getAttribute('type') === 'radio') {
				const radios = $input.closest('form').querySelectorAll(`input[name="${$input.getAttribute('name')}"]`)
				radios.forEach($radio => {
					$radio.classList.remove('is-invalid')
				})
			}
		})
	})
}
validate('.js-validate [required]')
const tabs = (selector) => {
	const buttons = document.querySelectorAll(selector)
	buttons.forEach($button => {
		const tabID = $button.dataset.tab.substr(1) || $button.getAttribute('href').substr(1)
		const $tab = document.getElementById(tabID)
		const sBtns = getSiblings($button.parentNode)
		const sTabs = getSiblings($tab)
		$button.addEventListener('click', (e) => {
			e.preventDefault()
			$button.classList.add('is-active')
			$tab.classList.add('is-visible')
			sBtns.forEach($sBtn => {
				if ($sBtn.querySelector('[data-tab]')) {
					$sBtn.querySelector('[data-tab]').classList.remove('is-active')
				}
			})
			sTabs.forEach($sTab => {
				$sTab.classList.remove('is-visible')
			})
		})
	})
}
tabs('[data-tab]')
const tabsSlideToggle = (selector) => {
	const buttons = document.querySelectorAll(selector)
	buttons.forEach($button => {
		const $tab = document.getElementById($button.dataset.tabToggle.substr(1))
		$button.addEventListener('click', (e) => {
			e.preventDefault()
			$button.classList.toggle('is-active')
			slideToggle($tab)
		})
	})
}
tabsSlideToggle('[data-tab-toggle]')

import inputmask from 'inputmask'
const $inputPhones = document.querySelectorAll('input[type="tel"]')
$inputPhones.forEach($input => {
	inputmask({
		mask: '+7 ( 999 ) 999 - 99 - 99',
		showMaskOnHover: false
	}).mask($input)
})

const workTime = () => {
	const arrWork = document.querySelectorAll('.work-time');
	arrWork.forEach($work => {
		const $btn = $work.querySelector('.work-time__head');
		const $dropdown = $work.querySelector('.work-time__dropdown');
		$btn.addEventListener('click', () => {
			$work.classList.toggle('is-open');
			slideToggle($dropdown)
		});
	})
}
workTime();
const arrToggleMenu = document.querySelectorAll('.js-toogle-submenu');
arrToggleMenu.forEach($btn => {
	let $dropdown = $btn.closest('.menu').querySelector('.menu__list');
	$btn.addEventListener('click', (e) => {
		const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
		if (width < 992) {
			e.preventDefault();
			$btn.classList.toggle('is-open');
			slideToggle($dropdown);
		}
	})
})
const slidetoggle = (selector) => {
	const arr = document.querySelectorAll(selector);
	arr.forEach($btn => {
		let $parent = typeof $btn.dataset.parent !== 'undefined' ? $btn.closest($btn.dataset.parent) : $btn.parentNode;
		let $dropdown = $parent.querySelector('[data-dropdown]');
		$btn.addEventListener('click', (e) => {
			e.preventDefault();
			slideToggle($dropdown, 500, () => {
				$parent.classList.remove('is-init-open')
			});
			$parent.classList.toggle('is-open')
		});
	});
}
slidetoggle('[data-slidetoggle]');

const arrFile = document.querySelectorAll('.file');
arrFile.forEach($file => {
	let $fileError = $file.querySelector('.file__error');
	let $input = $file.querySelector('input');
	$input.addEventListener('change', evt => {
		if ($file.querySelector('.file__images')) {
			$file.querySelector('.file__images').remove();
		}
		let files = evt.target.files;
		$input.classList.remove('is-invalid');
		if (files.length > 6) {
			$fileError.textContent = 'Не больше 6 файлов';
			$input.classList.add('is-invalid')
		} else {
			let $images = document.createElement('div');
			$images.classList.add('file__images');
			Array.from(files).forEach(file => {
				let $itm = document.createElement('div');
				$itm.classList.add('file__images-itm');
				let $img = document.createElement('img');
				$img.src = URL.createObjectURL(file);
				$itm.append($img)
				$images.append($itm)
			});
			$file.prepend($images)
		}
	})
})

const choiceBox = (selector) => {
	const arrBox = document.querySelectorAll(selector);
	arrBox.forEach($box => {
		let arrCheckbox = $box.querySelectorAll('input[type="checkbox"]');
		let $checkboxAll = $box.querySelector('.js-all');
		arrCheckbox.forEach($checkbox => {
			$checkbox.addEventListener('change', e => {
				if (e.target.classList.contains('js-all')) {
					if(e.target.checked) {
						arrCheckbox.forEach($check => {
							$check.checked = true;
						})
					} else {
						arrCheckbox.forEach($check => {
							$check.checked = false
						})
					}
				} else {
					$checkboxAll.checked = false;
				}
			});
		});
		document.addEventListener('click', e => {
			if (!e.target.closest(selector)) {
				const arrOpenBox = document.querySelectorAll(`${selector}.is-open`);
				arrOpenBox.forEach($boxOpen => {
					$boxOpen.classList.remove('is-open');
					slideUp($boxOpen.querySelector('[data-dropdown]'));
				});
			}
		})
	});
}
choiceBox('.choice-box')
const datepickers = document.querySelectorAll('[data-datepicker]');
import flatpickr from 'flatpickr';
import { Russian } from 'flatpickr/dist/l10n/ru.js'
datepickers.forEach($datepicker => {
	let calendar = null;
	let clear = $datepicker.parentNode.querySelector('.js-datepicker-clear')
	let init = () => {
		let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
		let showMonths = width < 992 ? 1 : 2;
		let options = {
			monthSelectorType: 'static',
			locale: Russian,
			showMonths,
			inline: true,
			dateFormat: 'd.m.Y',
			minDate: 'today',
			disable: [
				function (date) {
					return date.getDay() === 0 || date.getDay() === 6;
				}
			]
		};
		if (calendar) {
			calendar.destroy();
		}
		if (clear) {
			clear.addEventListener('click', evt => {
				evt.preventDefault();
				calendar.clear();
			});
		}
		calendar = flatpickr($datepicker, options);
	}
	init();
	window.addEventListener('resize', () => init())
	document.addEventListener('click', e => {
		if (!e.target.closest('.date-selection')) {
			const arrOpenBox = document.querySelectorAll('.date-selection.is-open');
			arrOpenBox.forEach($boxOpen => {
				$boxOpen.classList.remove('is-open');
				slideUp($boxOpen.querySelector('[data-dropdown]'));
			})
		}
	})
});
